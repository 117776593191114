import React from 'react';
import "./formElement.css";
import EditIndicate from "./EditIndicate";


const MyInput = ({label, status, ...props}) => {
    return(
        <div className="input">
            <label className="form-control-label">{label}</label><EditIndicate status={status} />
            <input {...props} />
        </div>
    )
}

export default MyInput;
