import {useState} from "react";


export const useFetching = (callBack) =>{
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async () => {

        try{
            setIsLoading(true);
            await callBack();
        }catch (e){

            if(e.message === 'Request failed with status code 401'){
                setError('Обновите страницу')
            }


        }finally {

            setIsLoading(false);
        }

    }

    return [fetching, isLoading, error]
}