import axios from "axios";
import $api from "./HttpService";
import RootAddress from "./RootAddress";

export default class GetService{

    static async getInit(){

        const response = await $api.get(RootAddress.getURL+"/api/v1/init/");

        return response.data;
    }

    static async get(target,id){

        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/get?id="+id);

        return response.data;
    }
    static async getAnother(target, what){

        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/get"+what);

        return response.data;
    }
    static async getAnotherId(target, what, id){

        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/get"+what+"?id="+id);

        return response.data;
    }
    static async getAll(target){

        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/list");

        return response.data;
    }
    static async getFullAll(target){

        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/full-list");

        return response.data;
    }


    static async getAllMy(target) {
        const response = await $api.get(RootAddress.getURL+"/api/v1/"+target+"/list-my");

        return response.data;
    }
}