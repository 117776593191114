import './app.css';


import React, {useEffect, useMemo, useState} from "react";
import RootAddress from "./API/RootAddress";
import AuthService from "./API/AuthService";
//import {UserContext} from "./context/UserContext";
import Sidebar from "./admin/component/sidebar/Sidebar";
import Main from "./admin/component/main/Main";
import Topbar from "./admin/component/topbar/Topbar";
import {BrowserRouter} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import AuthPage from "./admin/page/AuthPage";
import ApplicationProperties from "./admin/constant/ApplicationProperties";
import LoginMain from "./admin/component/auth/LoginMain";
import LanguageContext from "./admin/context/LanguageContext";

const App = () => {
  const [user, setUser] = useState(ApplicationProperties.defaultUser)
  const [auth, setAuth] = useState(false);

  const [sidebar, setSidebar] = useState(!isMobile);


  const providerUser = useMemo(()=>({user, setUser, auth, setAuth}), [user, setUser, auth, setAuth])

  const sidebarVisibility = () => {

    setSidebar(!sidebar)
  }

  const doCheck = () =>{
    if (localStorage.getItem('token')) {

      AuthService.checkAuth().then((r) => {
        if (r.status === 200) {
          localStorage.setItem('token', r.data.jwt)
          setUser(r.data)
          setAuth(true);

        } else {
          setAuth(false);
          console.log("Please login App")
        }
      })
    }
  }


  useEffect(()=>{
    doCheck()
    setInterval(()=> {
      doCheck()
    }, 1500000);
  }, [])



  const loginAnswer = (auth, user) => {

    setUser(user);
    setAuth(auth);

  }
  const logOut = () => {
    AuthService.logout().then(()=>{
      setAuth(false)
      setUser(ApplicationProperties.defaultUser)

    })
  }


  if(!auth){
    return(
        <BrowserRouter>
          <LanguageContext>
            <LoginMain loginAnswer={loginAnswer} />
          </LanguageContext>
        </BrowserRouter>
    )
  }
  else{
    return (
        <div className="App">
            <div>
              <BrowserRouter>
              <LanguageContext>
                <Topbar user={user} logOut={logOut} sidebarVisibility={sidebarVisibility}/>
                <div className="dashboard">

                <Sidebar user={user} style={{display: sidebar ? 'block' : 'none'}} />
                <Main user={user} isMobile={isMobile} auth={auth}/>

                </div>
              </LanguageContext>
            </BrowserRouter>
            </div>

        </div>
    );
  }


}

export default App;
