import React, {useEffect, useState} from "react";
import {useFetching} from "../../admin/hook/useFetching";
import GetService from "../../API/GetService";
import {Link, useParams} from "react-router-dom";
import { parse, serialize } from 'tinyduration';
import "./resultitem.css"
import {parsingTime, parsingDate} from "../../admin/utils/ToolService"

import LineChart from "../../admin/component/ui/chart/LineChart";
import Table from "../../admin/component/ui/table/Table";
import {Delete} from "@mui/icons-material";
import RawResult from "../../project/component/RawResult";
import {adminTranslations} from "../../admin/component/lang/language";
import {projectTranslations} from "../../project/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const ResultTest = () => {
    const {language} = useLanguage();
    const {id} = useParams();
    const[showList, setShowList] = useState(false);
    const[result, setResult] = useState([])
    const[resultCertain, setResultCertain] = useState(null)

    const[infoMessage, setInfoMessage] = useState(null)
    const[dataSetRaw, setDataSetRaw] = useState([])
    const[labelsRaw, setLabelsRaw] = useState([])


    const[getResult] = useFetching(async ()=>{
        const response = await GetService.getAnotherId("result","ByTest", id)

        setResult(response)
    }, [])

    const[getResultCertain] = useFetching(async ()=>{
        const response = await GetService.getAnotherId("result", "Certain", resultCertain.id)

        setInfoMessage(response)
    }, [])

    const updateCertain = (row) =>{
            setResultCertain(row)
    }

    const columns = React.useMemo(
        () => [

            {
                Header: adminTranslations.date[language],
                accessor: 'date',
                filterable : false,
                Cell:  ({ value, row }) => {

                    return <span className="fake_link" onClick={()=>{updateCertain(row.original)}}>{parsingDate(value)}</span>;
                },
                disableFilters:true,

            },
            {
                Header: adminTranslations.time[language],
                accessor: 'duration',
                filterable : false,
                Cell:  ({ value }) => parsingTime(value, language),
                disableFilters:true,
            },

            {
                Header: projectTranslations.correctAnswers[language],
                filterable : false,
                accessor: 'count',
                Cell:  ({ value }) => value,
                disableFilters:true,
            },



        ],
        []
    )



    useEffect(()=>{
        getResult().then(()=>{

        })


    },[])

    useEffect(()=>{

      if(result.length > 0){
          const maxDate = new Date(Math.max(...result.map(e => new Date(e.date)))).getTime();

          let arr = [];
          let leb = [];
          for(let c=0; c<result.length; c++){

              if(new Date(result[c].date).getTime() === maxDate){

                 // console.log(result[c])
                  setResultCertain(result[c])
              }
              arr.push(result[c].count)

              leb.push(c+1);

          }

          setDataSetRaw(arr);
          setLabelsRaw(leb)


      }
    },[result])


    useEffect(()=>{
        if(resultCertain !== null){
            getResultCertain()
        }

    }, [resultCertain])

    return(
        <div>
            <div>
                <div className="underPageBody">
                    <h1>{adminTranslations.results[language]}</h1>
                </div>
                <div className="pagebody">
                    <div className="section-wrapper">
                        <div className="tableButtons">


                        </div>
                        <div className="my_results_line">


                            <div className="my_results_line_list">
                                <div><Table fit={false} columns={columns} pageSizeRaw={4} data={result} /> </div>
                            </div>
                            {
                                result.length > 1 ? <div className="my_results_line_chart">
                                    <LineChart labelsRaw={labelsRaw} labelRaw={projectTranslations.correctAnswers[language]} titleRaw={projectTranslations.attempts[language]} dataSetRaw={dataSetRaw} />
                                </div> : ""
                            }
                        </div>

                        <div className="my_results_detail">
                            {
                                infoMessage !== null ? <RawResult infoMessage={infoMessage} /> : ""
                            }
                        </div>
                    </div>
                </div>
        </div>
        </div>
    )
}

export default ResultTest;