import RootAddress from "../../API/RootAddress";


export default class ApplicationProperties {

    static defaultUser = {firstName: 'Guest', lastName: '', avatar: RootAddress.getURL+'/website/images/img/none.jpg'};

    static validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
}

