import React from "react";
import {Link} from "react-router-dom";

const Info = ({infoMessage, linkArray}) => {

    const links = linkArray.map((link, id)=>{

        return <Link key={id} to={link.url}>{link.label}</Link>
    })

    return(<div className="loginModal">
        {infoMessage}
        <div className="auth__menu flex-auth-menu">
            <hr/>
            {
                links
            }

        </div>

    </div>)
}

export default Info;