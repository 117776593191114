import "./questionsimpleradio.css"
const QuestionSimpleRadio = ({question, display, select}) => {



    const options = question.options.map((q, key)=>{
        return         <div key={key} className="form_radio">
            <input onChange={(e)=>{select(question.id, e.target.value)}} type="radio" className="custom-checkbox" id={q.id} name={question.description} value={q.id} />
                <label htmlFor={q.id}>{q.title}</label>
        </div>


    });

    return <div  style={{display:display?'block' : 'none'}}>
       <p>{question.description}</p>
        <div className="question_options_list">{options}</div>

    </div>
}
export default QuestionSimpleRadio;