import { useStopwatch } from 'react-timer-hook';
import Question from "./Question";
import "./worktest.css"
import QuestionSimpleRadio from "./QuestionSimpleRadio";
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {useFetching} from "../../admin/hook/useFetching";
import ProgressLine from "./ProgressLine";
import InfoMessage from "./InfoModal";
import QuestionWorkBox from "./QuestionWorkBox";
import PostService from "../../API/PostService";
import Loader from "./Loader";
import RawResult from "./RawResult";
import {adminTranslations} from "../../admin/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const WorkTest = ({questions, test}) => {
    const {language} = useLanguage();
    const[current, setCurrent] = useState(0);
    const[questionSize] = useState(questions.length-1)
    const[answers, setAnswers] = useState([])
    const[progress, setProgress] = useState([{key: 0, value: "empty.png"}]);
    const[infoMessage, setInfoMessage] = useState("");
    const[infoModal, setInfoModal] = useState(false);
    const[waitResult, setWaitResult] = useState(false);
    const[statusResult, setStatusResult] = useState(false);
    const[totalQuestions] = useState(questions.length)
    const[timerShow, setTimerShow] = useState(true);

    const updateProgress = (idTest, idAnswer) =>{
        const temp = progress;
        const index = temp.findIndex((obj => obj.key === idTest));
        temp[index].value = "full.png";
        setProgress(temp)
    }

    const select = (idTest, idAnswer) => {
        updateProgress(idTest, idAnswer)
        const temp = answers;
        const index = temp.findIndex((obj => obj.key === idTest));
        if(index !== -1){
            temp[index].value = parseInt(idAnswer);
        }
        else{
            temp.push({key: idTest, value: parseInt(idAnswer)})
        }
        setAnswers(temp);


    }



    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });

    const questionList = questions.map(((question, key)=>{

        return <QuestionSimpleRadio select={select} display={key == current} key={key}  question={question}/>
    }))

    const prepareToSave = () => {


        setInfoModal(true)




    }

    const save = () =>{
        setStatusResult(true)

        const data = {test: test.id, answers: answers, total: totalQuestions, hours: hours, minutes: minutes, seconds: seconds}
        setTimerShow(false)
        PostService.check(data).then(r=>{
            setInfoMessage(r)
            setWaitResult(true)
            setStatusResult(false)
        })
    }

    useEffect(()=>{
        const temp = [];
        questions.forEach((question)=>{

            temp.push({key: question.id, value: "empty.png"});

        })
        setProgress(temp);
        //console.log(temp)
        }, []
    )

    const closeModal = () => {
        setInfoModal(false)
    }

    const backPage = () => {
        setCurrent(current-1)
    }
    const nextPage = () => {
        setCurrent(current+1)
    }

    return(
        <div>

                <div className="question_place">
                    <div className="question_box">
                        <div style={{display: timerShow ? 'block' : 'none'}} className="timer">{adminTranslations.minutes[language]} <span>{minutes}</span> {adminTranslations.seconds[language]} <span>{seconds}</span></div>
                        {
                            waitResult ?
                                <div>
                                    {
                                        statusResult ? <Loader /> : <RawResult infoMessage={infoMessage}/>
                                    }
                                </div>
                                :
                                <div>
                                    <InfoMessage count={answers.length} closeModal={closeModal} save={save} size={questionSize+1} modal={infoModal} />
                                    <QuestionWorkBox questionList={questionList} current={current} modal={infoModal} size={questionSize} progress={progress} back={backPage} next={nextPage} prepareToSave={prepareToSave} />
                                </div>
                        }


                    </div>



                </div>


        </div>
    )
}

export default WorkTest;