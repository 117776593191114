import React from "react";


export default class ToolService{

    static getIndicate(res) {
        if(res.status === 200){
            return 'done';
        }
        else{
            return 'error';
        }

    }
}