import $api from "./HttpService";
import RootAddress from "./RootAddress";

export default class SetService{

    static async set(id, value, target, object){
        const data = {id: id, value: value};

        const path = RootAddress.getURL+"/api/v1/"+target+"/edit/set"+object;

        const response = await $api.patch(path, data);

        return response.data;
    }


    static async setProfile(value, object) {
        const config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        };
        const path = RootAddress.getURL + "/api/v1/user/edit/set" + object;

        return await $api.patch(path, value, config);


    }
}