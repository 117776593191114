import React, {useEffect, useState} from "react";
import MyInput from "../ui/form/MyInput";
import ToolService from "../../../API/ToolService";
import SetService from "../../../API/SetService";
import RootAddress from "../../../API/RootAddress";
import {useFetching} from "../../hook/useFetching";
import GetService from "../../../API/GetService";
import Button from "react-bootstrap/Button";
import PostService from "../../../API/PostService";
import "./profile.css"
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
const Profile = ({title, user}) => {
    const {language} = useLanguage();
    const [firstnameIndicate, setFirstnameIndicate] = useState('');
    const [lastnameIndicate, setLastnameIndicate] = useState('');
    const [firstname, setFirstname] = useState('')
    const [avatar, setAvatar] = useState(user.avatar)
    const [newPassword, setNewPassword] = useState('')


    const [currentPasswordIndicate, setCurrentPasswordIndicate] = useState('');
    const [newPasswordIndicate, setNewPasswordIndicate] = useState('');
    const [currentPassword, setCurrentPassword] = useState('')
    const [lastname, setLastname] = useState('')

    const [repeatPasswordIndicate, setRepeatPasswordIndicate] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('')

    const [correctPasswords, setCorrectPasswords] = useState(false)



    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
    const [errorRepeatPasswordMessage, setErrorRepeatPasswordMessage]  = useState('');



    const [getUser] = useFetching(async () => {
        const response = await GetService.get("user");
        setFirstname(response.firstName)
        setLastname(response.lastName)
        setAvatar(response.avatar)
    })

    useEffect(()=>{
        getUser()
    }, [])


    const checkEmpty = (e) => {
        switch (e.target.name){
            case 'newPassword':

                if(e.target.value === ""){
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.errorPassword1[language])
                    setCorrectPasswords(false)
                }
                else if((e.target.value !== "" && repeatPassword !== "") && e.target.value !== repeatPassword ){
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.errorPassword3[language])
                    setCorrectPasswords(false)
                }
                else{

                    if(e.target.value.length <= 3 ){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword2[language])
                        setCorrectPasswords(false)
                    }
                    else{
                        setErrorRepeatPassword(false)
                        setCorrectPasswords(false)
                    }


                }
                break;
            case 'repeatPassword':
                if(e.target.value === ""){
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.errorPassword1[language])
                    setCorrectPasswords(false)
                }
                else{

                    if(newPassword === '' && (e.target.value.length <= 3  )){

                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword2[language])
                        setCorrectPasswords(false)
                    }
                    else if(newPassword !== '' && e.target.value !== newPassword){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword3[language])
                        setCorrectPasswords(false)
                    }
                    else{
                        setErrorRepeatPassword(false)
                        setErrorRepeatPasswordMessage("")
                        setCorrectPasswords(true)
                    }


                }
                break;
        }
    }


    const setIndicate = (res, object) =>{

        switch (object){
            case "FirstName":

                setFirstnameIndicate(ToolService.getIndicate(res));
            break;
            case "LastName":
                setLastnameIndicate(ToolService.getIndicate(res));
            break;

        }
    }

    const set = (value, object) => {
        const response =  SetService.setProfile(value, object)

        response.then(res=>{
           setIndicate(res, object)
        }).catch(r=>{
            setIndicate(r, object)
        })
    }

    const changeNewPassword = () => {

        if(correctPasswords){
            const response =  SetService.setProfile(repeatPassword, "MyPassword")

            response.then(res=>{
                setNewPasswordIndicate(ToolService.getIndicate(res));
                setRepeatPasswordIndicate(ToolService.getIndicate(res));
            }).catch(res=>{
                setNewPasswordIndicate(ToolService.getIndicate(res));
                setRepeatPasswordIndicate(ToolService.getIndicate(res));
            })
        }

    }

    const uploadFile = (file, object) => {
        const formData = new FormData();
        formData.append('file',file)
        const response =  SetService.upload(formData, object)
        response.then()

    }


    const checkPassword = () => {
        const response =  PostService.checkPassword(currentPassword)
        response.then(()=>{
            setCurrentPasswordIndicate('done')
        }).catch(()=>{
            setCurrentPasswordIndicate('error')
        })
    }

    return(
        <div>
            <div className="underPageBody">
                <h1>{title}</h1>
            </div>
            <div className="pagebody">
                <div className="section-wrapper">
                    <div className={"profile"}>
                        <div className="form-layout">
                            <div className="input-line">

                                <MyInput
                                    status={firstnameIndicate}
                                    value={firstname}
                                    label={adminTranslations.firstName[language]}
                                    onChange={e=>setFirstname(e.target.value)}
                                    onFocus={()=>setFirstnameIndicate('wait')}
                                    onBlur={()=>{set(firstname, "FirstName")}}
                                />
                                <MyInput
                                    status={lastnameIndicate}
                                    value={lastname}
                                    label={adminTranslations.lastName[language]}
                                    onChange={e=>setLastname(e.target.value)}
                                    onFocus={()=>setLastnameIndicate('wait')}
                                    onBlur={()=>{set(lastname, "LastName")}}
                                />
                            </div>
                            <h3>Смена пароля</h3>
                            <div className="input-line">

                                <MyInput
                                    status={currentPasswordIndicate}
                                    value={currentPassword}
                                    label={adminTranslations.currentPassword[language]}
                                    onChange={e=>setCurrentPassword(e.target.value)}
                                    onFocus={()=>setCurrentPasswordIndicate('wait')}
                                    onBlur={()=>{checkPassword()}}
                                    type={"password"}
                                />
                            </div>

                                {
                                    currentPasswordIndicate === 'done' ?
                                        <div>
                                            <div className="input-line">
                                        <MyInput
                                            status={newPasswordIndicate}
                                            value={newPassword}
                                            label={adminTranslations.newPasswordText[language]}
                                            name="newPassword"
                                            onChange={e=>setNewPassword(e.target.value)}
                                            onFocus={()=>setNewPasswordIndicate('wait')}
                                            onBlur={(e)=>{checkEmpty(e)}}
                                            type={"password"}

                                        />
                                        <MyInput
                                            status={repeatPasswordIndicate}
                                            value={repeatPassword}
                                            label={adminTranslations.repeatPasswordText[language]}
                                            name="repeatPassword"
                                            type={"password"}
                                            onChange={e=>setRepeatPassword(e.target.value)}
                                            onFocus={()=>setRepeatPasswordIndicate('wait')}
                                            onBlur={(e)=>{checkEmpty(e)}}
                                        />
                                        <div className="button__inside__flex">
                                            <Button variant="primary" disabled={!correctPasswords} onClick={()=>{changeNewPassword()}}>{adminTranslations.saveText[language]}</Button>
                                        </div>

                                            </div>
                                            <p className='wrongPassword' style={{display: errorRepeatPassword ? 'block' : 'none'}}>{errorRepeatPasswordMessage}</p>
                                        </div> : ""
                                }


                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Profile;