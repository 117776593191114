import React, {useEffect, useState} from "react";
import {useFetching} from "../../admin/hook/useFetching";
import GetService from "../../API/GetService";
import "./catalog.css"
import Test from "./Test";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {adminTranslations} from "../../admin/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const Catalog = ({title, user, goTest, filtered}) => {
    const {language} = useLanguage();
    const[tests, setTests] = useState([]);
    const[empty, setEmpty] = useState(false)

    const [getTests] = useFetching(async () =>{
        let response;
        if(!filtered){
            response = await GetService.getAll("test");
        }
        else{
            response = await GetService.getAllMy("test");

            if(response.length === 0){
                setEmpty(true);

            }

        }

        setTests(response);
    });

    useEffect(()=>{
        getTests().then(r=>{


        });

    }, [])

    useEffect(()=>{
        document.title = title
    },[title])


    const drawTest = tests.map((test, key)=>{
        return <Test goTest={goTest} user={user} test={test} key={key}/>
    })

    return(
        <div>
            <div className="underPageBody">
                <h1>{title}</h1>
            </div>
            <div className="pagebody">
                <div className="section-wrapper">
                    <div className="tableButtons">


                    </div>
        <div className={"catalog__board"}>
            {empty ? <div><Link to={"/catalog/"} ><Button variant="primary"  >{adminTranslations.toCatalog[language]}</Button> </Link></div> :
            drawTest
        }
    </div>
                </div>
            </div>

        </div>
    )
}

export default Catalog;