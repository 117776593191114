import React, {useState} from "react";

import ApplicationProperties from "../../constant/ApplicationProperties";
import AuthService from "../../../API/AuthService";
import {Link} from "react-router-dom";
import Info from "./Info";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
import ReactFlagsSelect from "react-flags-select";

const Registration = () => {
    const {language, setLanguage} = useLanguage();
    const [languageSelected, setLanguageSelected] = useState(language);

    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const [password, setPassword] = useState("");

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
    const [errorRepeatPasswordMessage, setErrorRepeatPasswordMessage]  = useState('');

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailMessage, setErrorEmailMessage]  = useState('');

    const [emptyErrorLastname, setEmptyErrorLastname] = useState(false);
    const [emptyErrorFirstname, setEmptyErrorFirstname] = useState(false);
    const [errorPasswordMessage, setErrorPasswordMessage]  = useState('');
    const [infoMessage, setInfoMessage] = useState('')

    const [infoShow, setInfoShow] = useState(false)

    const linkArray = [{url: '/', label: adminTranslations.login[language]}]

    const changeLanguage = (code) => {
        setLanguage(code)
        setLanguageSelected(code)

    }

    const checkEmpty = (e) => {

        switch (e.target.name){
            case 'firstName':
                if(e.target.value === ""){
                    setEmptyErrorFirstname(true)
                }
                else{
                    setEmptyErrorFirstname(false)
                }

                break;
            case 'lastName':
                if(e.target.value === ""){
                    setEmptyErrorLastname(true)
                }
                else{
                    setEmptyErrorLastname(false)
                }
                break;
            case 'email':
                if(e.target.value === ""){
                    setErrorEmail(true)
                    setErrorEmailMessage(adminTranslations.errorPassword1[language])
                }
                else{

                    if(ApplicationProperties.validateEmail(e.target.value) === null){
                        setErrorEmail(true)
                        setErrorEmailMessage(adminTranslations.errorEmail1[language])
                    }else{
                        setErrorEmail(false)
                        setErrorEmailMessage("")
                    }

                }
                break;
            case 'password':

                if(e.target.value === ""){
                    setErrorPassword(true)
                    setErrorPasswordMessage(adminTranslations.errorPassword1[language])

                }
                else{

                    if(e.target.value.length <= 3 ){
                        setErrorPassword(true)
                        setErrorPasswordMessage(adminTranslations.errorPassword2[language])
                    }
                    else{
                        setErrorPassword(false)
                    }


                }
                break;
            case 'repeatPassword':
                if(e.target.value === ""){
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.errorPassword1[language])
                }
                else{
                    if(password === '' && (e.target.value.length <= 3 )){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword2[language])
                    }
                    else if(password !== '' && e.target.value !== password){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword3[language])
                    }
                    else{
                        setErrorRepeatPassword(false)
                        setErrorRepeatPasswordMessage("")
                    }


                }
                break;
        }
    }

    const registration = () => {

        const correct = password === repeatPassword;

        if(correct){
            const data = {firstName: firstname, lastName: lastname, password: password, email: email, language: language}

            setErrorPassword(false);
            AuthService.registration(data).then((r)=>{

                const message = adminTranslations.confirmEmail[language]
                setInfoShow(true)
                setInfoMessage(message)
                setErrorEmail(false)
                setErrorRepeatPassword(false)
            }).catch((reason => {
                const status = reason.response.status;

             //   let message = "Что-то пошло не так";
                if(status === 409){
                    setErrorEmail(true)
                    setErrorEmailMessage(adminTranslations.existEmail[language])
                  //  message  = "Пользователь с таким email уже зарегистрирован."
                }
                else if(status === 400){

                   // message  = "Ошибка в отправленных данных."
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.sentError[language])
                }
               // setInfoMessage(message)
               // setInfoShow(true)

            }))
        }
        else{
            setErrorPassword(true);
        }


    }



    if(infoShow){
        return <Info linkArray={linkArray} infoMessage={infoMessage}/>
    }
    else{
        return(
            <div  className="loginModal">
                <div className="languageLogin">
                    <ReactFlagsSelect
                        selected={languageSelected}
                        countries={["US", "RU"]}
                        customLabels={{"US": "English","RU": "Русский"}}
                        placeholder="Select Language"
                        onSelect={(code) => changeLanguage(code)}
                    />
                </div>
                <input name="firstName" onBlur={(e)=>{checkEmpty(e)}} type="text" placeholder={adminTranslations.firstName[language]} onChange={(e)=>{setFirstname(e.target.value)}} value={firstname} />
                <p className='wrongPassword' style={{display: emptyErrorFirstname ? 'block' : 'none'}}>{adminTranslations.errorPassword1[language]}</p>

                <input name="lastName" onBlur={(e)=>{checkEmpty(e)}} type="text" placeholder={adminTranslations.lastName[language]} onChange={(e)=>{setLastname(e.target.value)}} value={lastname} />
                <p className='wrongPassword' style={{display: emptyErrorLastname ? 'block' : 'none'}}>{adminTranslations.errorPassword1[language]}</p>

                <input name="email" type="email" placeholder="Email" onBlur={(e)=>{checkEmpty(e)}} onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                <p className='wrongPassword' style={{display: errorEmail ? 'block' : 'none'}}>{errorEmailMessage}</p>

                <input  name="password" type="password" onBlur={(e)=>{checkEmpty(e)}} className={errorPassword ? 'red__border' : ''}  placeholder={adminTranslations.passwordText[language]} onChange={(e)=>{setPassword(e.target.value)}} value={password} />
                <p className='wrongPassword' style={{display: errorPassword ? 'block' : 'none'}}>{errorPasswordMessage}</p>

                <input name="repeatPassword" onBlur={(e)=>{checkEmpty(e)}} type="password" className={errorPassword ? 'red__border' : ''} placeholder={adminTranslations.repeatPasswordText[language]} onChange={(e)=>{setRepeatPassword(e.target.value)}} value={repeatPassword} />
                <p className='wrongPassword' style={{display: errorRepeatPassword ? 'block' : 'none'}}>{errorRepeatPasswordMessage}</p>


                <button onClick={()=>registration()}>{adminTranslations.registrationText[language]}</button>
                <div className="auth__menu">
                    <hr/>
                    <div className="select__reg_log" ><Link to={'/'}>{adminTranslations.login[language]}</Link></div>
                </div>
            </div>
        )
    }


}

export default Registration;