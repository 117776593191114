import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Information from "../../project/component/Information";
import WorkTest from "../../project/component/WorkTest";
import {useFetching} from "../../admin/hook/useFetching";
import PostService from "../../API/PostService";

const Workplace = ({test}) => {
    const history = useHistory();
    const [testStatus, setTestStatus] = useState(false);

    const [questions, setQuestions] = useState([]);
    const [getQuestions] = useFetching(async ()=>{

        const response = await PostService.getQuestions(test.id);

        setQuestions(response)
    })

    useEffect(()=>{
        if(test !== null){
            getQuestions();

        }else{
            history.push(`/`);
        }
    }, [test])

    const start = () =>{

        setTestStatus(true)
    }

    return(
        <div className="pagebody">

                {
                    testStatus ?
                        <WorkTest questions={questions} test={test}/> :
                        <Information start={start} test={test} />

                }



        </div>
    )
}

export default Workplace;