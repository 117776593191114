import PostService from "../../API/PostService";
import {useEffect, useState} from "react";
import "./test.css"
import Button from "react-bootstrap/Button";
import {adminTranslations} from "../../admin/component/lang/language";
import {projectTranslations} from "../../project/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const Test = ({test, user, goTest}) => {
    const {language} = useLanguage();
    const[bought, setBought] = useState(false);
    const[freeActive, setFreeActive] = useState(true);
    const[basicActive, setBasicActive] = useState(false);
    const[proActive, setProActive] = useState(false);
    const[currentPrice, setCurrentPrice] = useState(test.price)

    const buyTest = () => {
        if(test.price === 0){
            PostService.addFree(test.id).then((r)=>{

                setBought(true)
            })
        }
    }

    const changeActivePlan = (value) => {
        switch (value){
            case "free":
                setCurrentPrice(test.price)
                setFreeActive(true)
                setBasicActive(false)
                setProActive(false)
            break;
            case "basic":
                setCurrentPrice(test.price+2.99)
                setFreeActive(false)
                setBasicActive(true)
                setProActive(false)
            break;
            case "pro":
                setCurrentPrice(test.price+14.99)
                setFreeActive(false)
                setBasicActive(false)
                setProActive(true)
            break;

        }
    }

    useEffect(()=>{

        if(user.tests.includes(test.id)){
            setBought(true)
        }
    }, [])


    return(
        <div className={"test"} >

            <div className={"test__name"}>{test.title}</div>
            <div className={"test__description"}>{test.description}</div>
            <div className="test__image">
                <img src={process.env.PUBLIC_URL+'/img/english_comm_header.jpg'}  />
            </div>
            <hr/>

            <div className={"test__detail"}>
                <div>{

                }
                    <b>{projectTranslations.testDetailUser[language].first}</b> {test.numberQuizQuestions}
                </div>
                <div>
                    <b>{projectTranslations.testDetailUser[language].second}</b> <span>{projectTranslations.testDetailUser[language].third}</span>
                </div>
                <div>
                    <b>${currentPrice}</b>
                    <div className={"test__select__plan"}>
                        <span onClick={()=>{changeActivePlan("free")}} className={freeActive ? "active__plan" : "" }>Free</span>
                        { /* <span onClick={()=>{changeActivePlan("basic")}} className={basicActive ? "active__plan" : "" }>Basic</span>
                        <span onClick={()=>{changeActivePlan("pro")}} className={proActive ? "active__plan" : "" }>Pro</span> */}
                    </div>
                </div>
            </div>

            <div className={"test__buttons"}>{bought ?  <Button variant="primary" onClick={()=>{goTest(test)}}>{projectTranslations.passTest[language]}</Button>: <Button onClick={()=>{buyTest()}}>{adminTranslations.add[language]}</Button>}</div>
        </div>
    )
}

export default Test;