import OneDot from "./OneDot";
import "./progressline.css"
import {useEffect, useState} from "react";

const ProgressLine = ({progress}) => {

    const [myProgress, setMyProgress] = useState(progress)


    const progressLine = progress.map((entity, key)=>{
        return <OneDot key={key} entity={entity}/>
    })

    return(
        <div className="progress_line">

                 {progressLine}
        </div>
    )
}

export default ProgressLine;