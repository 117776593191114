import React from 'react';
import "./sidebar.css";

import {
    AddShoppingCartOutlined, ShowChartOutlined, ViewListOutlined
} from '@mui/icons-material';
import SimpleMenuItem from "../ui/menu/SimpleMenuItem";

import {adminTranslations} from "../lang/language";
import {projectTranslations} from "../../../project/component/lang/language";
import {useLanguage} from "../../context/LanguageContext"
export default function Sidebar({user, ...props}){
    const {language} = useLanguage();
    return (
        <div className="sidebar"  {...props}>
            <label className="sidebar-label">Navigation</label>
            <div className="leftMenu">
                <SimpleMenuItem item={{icon: <AddShoppingCartOutlined/>, title: adminTranslations.catalog[language], alias: "/catalog"}}/>
                <SimpleMenuItem item={{icon: <ViewListOutlined/>, title: projectTranslations.myTests[language], alias: "/"}}/>
                <SimpleMenuItem item={{icon: <ShowChartOutlined/>, title: adminTranslations.results[language], alias: "/results"}}/>
            </div>


        </div>
    )
}