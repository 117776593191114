import React from 'react';
import "./formElement.css";
import {AccessTimeFilled, CheckCircle, Error} from '@mui/icons-material';
const EditIndicate = ({status}) => {
    let icon;
    switch(status){
        case "done":
            icon = <CheckCircle className="editIndicate doneIndicate"  />
            break
        case "wait":
            icon = <AccessTimeFilled className="editIndicate waitIndicate"  />
            break
        case "error":
            icon = <Error className="editIndicate errorIndicate"  />
            break
        default:
            icon = ''
    }

    return(
        icon
    )
}

export default EditIndicate;