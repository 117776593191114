import React, {useEffect, useState} from "react";
import AuthService from "../../../API/AuthService";
import ApplicationProperties from "../../constant/ApplicationProperties";
import {Link} from "react-router-dom";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
import ReactFlagsSelect from "react-flags-select";
import SetService from "../../../API/SetService";

const Login = ({loginAnswer}) => {
    const {language, setLanguage} = useLanguage();
    const [languageSelected, setLanguageSelected] = useState(language);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(adminTranslations.error403[language]);
    const [errorLogin, setErrorLogin] = useState(false);

    const changeLanguage = (code) => {
        setLanguage(code)
        setLanguageSelected(code)

    }



    const login = () => {

        const data = {email: email, password: password, language: language}

        AuthService.login(data).then((r)=>{

                    localStorage.setItem('token',r.data.jwt)
                    localStorage.setItem('refreshToken',r.data.refreshToken.token)
                    loginAnswer(true, r.data)


        }).catch((e)=>{

            switch (e.response.status){
                case 403:
                    setErrorLogin(true)
                    setErrorMessage(adminTranslations.error403_2[language])
                    loginAnswer(false, ApplicationProperties.defaultUser)
                    break
                case 423:
                    setErrorLogin(true)
                    setErrorMessage(adminTranslations.error403_2[language])
                    loginAnswer(false, ApplicationProperties.defaultUser)
                    break
                case 412:
                    setErrorLogin(true)
                    setErrorMessage(adminTranslations.error412[language])
                    loginAnswer(false, ApplicationProperties.defaultUser)
                    break
                default:
                    setErrorLogin(true)
                    setErrorMessage(adminTranslations.error0[language])
                    loginAnswer(false, ApplicationProperties.defaultUser)
            }
        });

    }
    return(
        <div  className="loginModal">
            <div className="languageLogin">
                <ReactFlagsSelect
                    selected={languageSelected}
                    countries={["US", "RU"]}
                    customLabels={{"US": "English","RU": "Русский"}}
                    placeholder="Select Language"
                    onSelect={(code) => changeLanguage(code)}
                />
            </div>

            <input name="email" type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} value={email} />
            <input name="password" type="password" placeholder={adminTranslations.passwordText[language]} onChange={(e)=>{setPassword(e.target.value)}} value={password} />
            {errorLogin &&
            <p className="errorLogin">
                {errorMessage}
            </p>
            }

            <button onClick={()=>login()}>{adminTranslations.login[language]}</button>
            <div className="auth__menu">
                <hr/>
                <div className="select__reg_log" ><Link to={'/registration'}>{adminTranslations.registrationText[language]}</Link></div>
            <div className="select__reg_log" ><Link to={'/reset-password'}>{adminTranslations.resetPasswordText[language]}</Link></div>
            </div>
        </div>
    )
}

export default Login;