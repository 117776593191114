import React, {useEffect, useState} from 'react';
import "./topbar.css";
import { Menu } from '@mui/icons-material';

import {Link} from "react-router-dom";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
import ReactFlagsSelect from "react-flags-select";
import SetService from "../../../API/SetService";


export default function Topbar({sidebarVisibility, user, logOut}){
    const {language, setLanguage} = useLanguage();
    const [dropUserMenu, setDropUserMenu] = useState(false);
    const [languageSelected, setLanguageSelected] = useState(language);

    const changeLanguage = (code) => {
        setLanguage(code)
        setLanguageSelected(code)
        SetService.setProfile(code, "Language")
    }

    useEffect(()=>{
        changeLanguage(user.language)
    }, [])


    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topbarLeft topbarBlock">
                    <div>
                        <div className="nameCompany"><span className="red">Many</span></div>

                        <div className="nameCompany"><span className="mainColor">Tests</span></div>
                    </div>


                </div>
                <div className="topbarMain">
                    <div className="menuIcon topbarBlock">
                        <Menu onClick={()=>{sidebarVisibility()}} />
                    </div>
                    <div className="languageUserBlock">
                        <div>
                            <ReactFlagsSelect
                                selected={languageSelected}
                                countries={["US", "RU"]}
                                customLabels={{"US": "English","RU": "Русский"}}
                                placeholder="Select Language"
                                onSelect={(code) => changeLanguage(code)}
                            />
                        </div>
                        <div onClick={()=>{setDropUserMenu(!dropUserMenu)}} className="topbarUser">
                            <span className="logged-name hidden-md-down">{user.firstName} {user.lastName}</span>
                            <img src="" className="rounded-circle" alt=""/>


                        </div>
                    </div>

                    {dropUserMenu && <div className="dropdown profileDropDown">

                        <ul>
                            <li onClick={()=>setDropUserMenu(false)}><Link to={'/profile/'}><PowerSettingsNewIcon className="userMenuIcon"/> {adminTranslations.profile[language]}</Link></li>
                            <li onClick={()=>logOut()}><span><PersonIcon className="userMenuIcon"/>{adminTranslations.exit[language]}</span></li>
                        </ul>


                    </div>}
                </div>
            </div>

        </div>
    )
}