import {Spinner} from "react-bootstrap";
import {adminTranslations} from "../../admin/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const Loader = () => {
    const {language} = useLanguage();
    return (
        <div>
           {adminTranslations.checking[language]}

            <Spinner animation="border" role="status">
                <span className="visually-hidden">{adminTranslations.loading[language]}</span>
            </Spinner>
        </div>
    )
}

export default Loader;