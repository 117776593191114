import axios from "axios";
import $api from "./HttpService";
import RootAddress from "./RootAddress";

export default class PostService{
    static async check(data){

        const response = await $api.post(RootAddress.getURL+"/api/v1/test/check", data);

        return response.data;
    }
    static async postInit(){

        const response = await $api.post(RootAddress.getURL+"/api/v1/create-month/");

        return response.data;
    }
    static async addFree(id){
        const config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        };
        const response = await $api.post(RootAddress.getURL+"/api/v1/test/add-free", id, config);

        return response.data;
    }

    static async add(data, target){

        const response = await $api.post(RootAddress.getURL+"/api/v1/"+target+"/add", data);

        return response.data;
    }

    static async addAnother(data, target, what){

        const response = await $api.post(RootAddress.getURL+"/api/v1/"+target+"/add"+what, data);

        return response.data;
    }

    static async getQuestions(id) {
        const config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        };
        const response = await $api.post(RootAddress.getURL+"/api/v1/test/full-test", id, config);

        return response.data;
    }

    static async checkPassword(currentPassword) {
        const config = {
            headers: {
                'Content-Type': 'text/plain'
            }
        };
        const response = await $api.post(RootAddress.getURL+"/api/v1/user/check-password", currentPassword, config);

        return response.data;
    }
}