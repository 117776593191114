import {BrowserRouter, Route, Switch} from "react-router-dom";
import React from "react";
import Registration from "./Registration";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import NewPassword from "./NewPassword";
import NoMatch from "../404/NoMatch";

const LoginMain = ({loginAnswer}) =>{
    return (
        <div className="App">
            <div className="loginPage">
              <BrowserRouter>
                  <Switch>
                      <Route exact  path="/">
                          <Login loginAnswer={loginAnswer} />
                      </Route>
                      <Route exact  path="/registration">
                          <Registration />
                      </Route>
                      <Route exact path="/reset-password">
                          <ResetPassword />
                      </Route>
                      <Route exact path="/new-password/:token">
                          <NewPassword />
                      </Route>
                      <Route path="*">
                          <NoMatch />
                      </Route>
                  </Switch>
              </BrowserRouter>


        </div>
        </div>
    )
}

export default LoginMain;