import ProgressLine from "./ProgressLine";
import Button from "react-bootstrap/Button";
import {adminTranslations} from "../../admin/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const QuestionWorkBox = ({modal, questionList, back, next, size, current, prepareToSave, progress}) => {
    const {language} = useLanguage();
    return(
        <div style={{display: modal ?   'none':'block'}}>
            <div className="question_progress">
                <ProgressLine progress={progress} />
            </div>
            {questionList}
            <div className="question_buttons_main">
                <div className="question_buttons_start"><Button variant="secondary" onClick={()=>{back()}} style={{display: current>0 ? 'block' : 'none'}}>{adminTranslations.prevPageText[language]}</Button><Button variant="primary" style={{display: current<size ? 'block' : 'none'}} onClick={()=>{next()}}>{adminTranslations.nextPageText[language]}</Button></div>
                <div className="question_buttons_end"><Button variant="success" style={{display: current===size ? 'block' : 'none'}} onClick={()=>{prepareToSave()}}>{adminTranslations.saveText[language]}</Button></div>

            </div>
        </div>
    )
}
export default QuestionWorkBox;