import {parse} from "tinyduration";


import {adminTranslations} from "../component/lang/language";
export default function isAdmin(user){
   return user.roles.includes("ROLE_ADMIN")
}

export function parsingTime(value, language){
   const t = parse(value)
   let s = "";
   if(t.hours !== undefined){
      s += t.hours+adminTranslations.hoursShort[language]
   }
   if(t.minutes !== undefined){
      s += t.minutes+adminTranslations.minutesShort[language]
   }
   if(t.seconds !== undefined){
      s += t.seconds+adminTranslations.secondsShort[language]
   }
   return s;
}

export function parsingDate(value){
   let date = new Date( Date.parse(value) )
  return date.getDate()+"."+(date.getMonth()+1)+"."+date.getFullYear()+" "+date.getHours()+":"+date.getMinutes()
}