
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"

const NoMatch = () => {
    const {language} = useLanguage();

    const history = useHistory();
useEffect(()=>{
    history.push(`/`);
}, [])
    return (
        <div>
            <h3>
                {adminTranslations.error404[language]}

            </h3>
        </div>
    );
}

export default NoMatch;