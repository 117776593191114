import React from 'react';
import "./menu.css";
import {Link} from "react-router-dom";
export default function SimpleMenuItem(props){
    return (
        <Link to={props.item.alias || '/'} className="menuItem">
            <span className="menuItemIcon">{props.item.icon}</span>
             <span className="menuItemTitle">{props.item.title}</span>
        </Link>
    )

}