import React, {useContext, useState} from 'react'

const LanguageContext = React.createContext()
const LanguageUpdateContext = React.createContext()

export const useLanguage = () => {
    return useContext(LanguageContext)
}
export const useLanguageUpdate = () => {
    return useContext(LanguageUpdateContext)
}

const LanguageProvider = ({children}) => {
const [language, setLanguage] = useState('US')

const selectLanguage = (value) => {
    setLanguage(value);
}

    return(
        <LanguageContext.Provider value={{language, setLanguage}}>

                {children}
        </LanguageContext.Provider>
    )
}

export default LanguageProvider;
