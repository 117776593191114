import React from "react";

export const projectTranslations = {

    myTests:{
        "RU": "Мои тесты",
        "US": "My tests"
    },
    testCatalog:{
        "RU": "Каталог тестов",
        "US": "Test Catalog"
    },

    correctAnswers:{
        "RU": "Верных ответов",
        "US": "Correct answers"
    },
    attempts:{
        "RU": "Попыток",
        "US": "Attempts"
    },
    testText:{
        "RU": "Тест",
        "US": "Test"
    },
    dateLast:{
        "RU": "Дата последней",
        "US": "Recent attempt date"
    },
    passTest:{
        "RU": "Пройти",
        "US": "Start"
    },


    testDetailUser:{
        "RU": {
            first: "Количество вопросов:",
            second: "Время:",
            third: "Неограничено",

        },
        "US": {
            first: "Number of questions",
            second: "Time",
            third: "Unlimited",

        }
    },
    confirmTest:{
        "RU": {
            first: "Отправляем?",
            second: "Вы заполнили",
            third: "из",

        },
        "US": {
            first: "Sending?",
            second: "You have filled",
            third: "out of",

        }
    },
    rawResult:{
        "RU": {
            first: "Результат от", //речь про дату (от какой даты)
            second: "Верных ответов", //Верных ответов 5 из 5, например
            third: "из",
            fourth: "Потрачено времени:",

        },
        "US": {
            first: "The result dated...",
            second: "Correct answers",
            third: "out of",
            fourth: "Time spent"

        }
    },

    //14.06.22
    yourLevel:{
        "RU": "Ваш уровень",
        "US": "Your level"
    }

}