import Button from "react-bootstrap/Button";
import {adminTranslations} from "../../admin/component/lang/language";
import {projectTranslations} from "./lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const InfoMessage = ({count, size, closeModal, modal, save}) => {
    const {language} = useLanguage();
    return(
        <div style={{display: modal ? 'block' : 'none'}}>
            <h2>{projectTranslations.confirmTest[language].first}</h2>
            <p>{projectTranslations.confirmTest[language].second} <b>{count}</b> {projectTranslations.confirmTest[language].third} <b>{size}</b></p>
            <div className="question_buttons_start">
                <Button variant="danger" onClick={()=>{closeModal()}}>{adminTranslations.cancelText[language]}</Button>
                <Button variant="primary"  onClick={()=>{save()}}>{adminTranslations.saveText[language]}</Button></div>
        </div>
    )
}

export default InfoMessage;