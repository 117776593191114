import Profile from "../profile/Profile";
import React from "react";

export const adminTranslations = {
    login: {
        "RU": "Войти",
        "US": "Log in"
    },
    profile:{
        "RU": "Профиль",
        "US": "Profile"
    },
    passwordText:{
        "RU": "Пароль",
        "US": "Password"
    },

    start:{
        "RU": "Начать",
        "US": "Start"
    },
    catalog:{
        "RU": "Каталог",
        "US": "Catalog"
    },
    checking:{
        "RU": "Проверяем...",
        "US": "Checking"
    },
    loading:{
        "RU": "Загрузка...",
        "US": "Loading..."
    },
    toCatalog:{
        "RU": "В каталог",
        "US": "Enter the catalog"
    },
    error404:{
        "RU": "Не найдено",
        "US": "not found"
    },
    error403:{
        "RU": "Ошибка авторизации",
        "US": "Authorization error"
    },
    error403_2:{
        "RU": "Неверный логин или пароль",
        "US": "Invalid login or password"
    },
    error423:{
        "RU": "Вы заблокированы",
        "US": "You are blocked"
    },
    error412:{
        "RU": "Вы не подтвердили email",
        "US": "The email address is not verified"
    },
    error500:{
        "RU": "Произошла ошибка, попробуйте снова или обратитесь в тех. поддержку...",
        "US": "An error has occured, try again or contact the support team"
    },
    error0:{
        "RU": "Произошла неизвестная ошибка",
        "US": "An unexpected error occured"
    },
    registrationText:{
        "RU": "Регистрация",
        "US": "Registration"
    },
    results:{
        "RU": "Результаты",
        "US": "Results"
    },
    exit:{
        "RU": "Выход",
        "US": "Exit"
    },
    nextPageText:{
        "RU": "Далее",
        "US": "Next"
    },
    prevPageText:{
        "RU": "Назад",
        "US": "Back"
    },
    cancelText:{
        "RU": "Отмена",
        "US": "Cancel"
    },
    resetPasswordText:{
        "RU": "Восстановить",
        "US": "Restore"
    },

    errorPassword1:{
        "RU": "Поле должно быть заполнено",
        "US": "Required field must be filled in"
    },
    errorEmail1:{
        "RU": "Неверный email",
        "US": "Default email"
    },
    errorPassword2:{
        "RU": "Пароль не менее 4 символов",
        "US": "Your password must have more than 4 symbols"
    },
    errorPassword3:{
        "RU": "Пароли не совпадают",
        "US": "The passwords don't match"
    },
    errorPassword0:{
        "RU": "Произошла неизвестная ошибка",
        "US": "An unexpected error occured"
    },
    repeatPasswordText:{
        "RU": "Повторите пароль",
        "US": "Reenter your password"
    },
    savePasswordText:{
        "RU": "Сохранить пароль",
        "US": "Save your password"
    },
    newPasswordText:{
        "RU": "Новый пароль",
        "US": "New password"
    },
    saveText:{
        "RU": "Сохранить",
        "US": "Save"
    },
    confirmEmail:{
        "RU": "На указанный электронный адрес отправлено письмо с подвтерждением регистрации.",
        "US": "Your registration confirmation email has been sent to your email address"
    },
    existEmail:{
        "RU": "Пользователь с таким email уже зарегистрирован.",
        "US": "A user with this email is already registered"
    },
    currentPassword:{
        "RU": "Введите текущий пароль",
        "US": "Enter your current password"
    },
    wrongEmail:{
        "RU": "Пользователь с таким email не найден.",
        "US": "No user with this email address has been found"
    },
    sentError:{
        "RU": "Ошибка в отправленных данных.",
        "US": "There was an error sending your data"
    },
    sentEmailInfo:{
        "RU": "Вам на почту отправлено письмо с дальнейшими инструкциями.",
        "US": "You next steps email has been sent to your email address"
    },
    firstName:{
        "RU": "Имя",
        "US": "Name"
    },
    lastName:{
        "RU": "Фамилия",
        "US": "Family Name"
    },
    resetPasswordText2:{
        "RU": "Сбрасываем пароль...",
        "US": "Resetting your password"
    },
    date:{
        "RU": "Дата",
        "US": "Date"
    },
    time:{
        "RU": "Время",
        "US": "Time"
    },


    add:{
        "RU": "Добавить",
        "US": "Add"
    },
    minutes:{
        "RU": "Минут:",
        "US": "Minutes"
    },
    seconds:{
        "RU": "Секунд:",
        "US": "Seconds"
    },
    hoursShort:{
        "RU": "ч ",
        "US": "h "
    },
    minutesShort:{
        "RU": "м ",
        "US": "m "
    },
    secondsShort:{
        "RU": "с",
        "US": "s"
    },
    attentionDeleteUser:{
        "RU": {
            first: "Внимание!",
            second: "Вы собираетесь",
            third: "УДАЛИТЬ",
            fourth: "Пользователя:"
        },
        "US": {
            first: "Attention!",
            second: "You want to...",
            third: "Delete",
            fourth: "the User"
        }
    },


}