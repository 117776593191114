import React, {useEffect, useState} from "react";
import {useFetching} from "../../admin/hook/useFetching";
import {Link} from "react-router-dom";
import GetService from "../../API/GetService";
import {Delete} from "@mui/icons-material";
import Table from "../../admin/component/ui/table/Table";
import {adminTranslations} from "../../admin/component/lang/language";
import {projectTranslations} from "../../project/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const ResultList = () => {
    const {language} = useLanguage();
    const[data, setData] = useState([]);

    const [getResults] = useFetching( async () =>{
        const response = await GetService.getAll("result")
        setData(response)

    })



    useEffect(()=>{
        getResults().then((r)=>{

        });
    }, [])

  /*  useEffect(()=>{
        document.title = title
    },[title])
    */


    const columns = React.useMemo(
        () => [

            {
                Header: projectTranslations.testText[language],
                accessor: 'title',
                Cell: ({ value, row }) => (<Link to={{pathname:'/results/get/'+`${row.original.id}` } }>{value}</Link>),
                filterable : true,
                width: 400,
                maxWidth: 400,
                minWidth: 400,
            },
            {
                Header: projectTranslations.attempts[language],
                accessor: 'times',
                Cell:  ({ value }) => value,
                disableFilters:true,
            },
            {
                Header: projectTranslations.dateLast[language],
                accessor: 'date',
                filterable : false,
                Cell:  ({ value }) => {
                    let date = new Date( Date.parse(value) )
                    return date.toDateString()+" "+date.toLocaleTimeString();
                },
                disableFilters:true,

            },




        ],
        []
    )

    return(
        <div>
            <div className="underPageBody">
                <h1>{adminTranslations.results[language]}</h1>
            </div>
            <div className="pagebody">
                <div className="section-wrapper">
                    <div className="tableButtons">


                    </div>
            {
                data.length > 0 ? <Table pageSizeRaw={10} fit={true} columns={columns} data={data}/> : <div></div>
            }
                </div>
            </div>

        </div>
    )
}

export default ResultList;