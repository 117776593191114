import axios from "axios";
import RootAddress from "./RootAddress";

export default class AuthService{

    static async login(data){

        return await axios.post(RootAddress.getURL+"/api/login/", data, {withCredentials: true});

    }

    static async registration(data){

        return await axios.post(RootAddress.getURL+"/api/registration/", data, {withCredentials: true});

    }

    static async newPassword(data){

        return await axios.post(RootAddress.getURL+"/api/new-password/", data, {withCredentials: true});

    }

    static async logout(){
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }
    static async resetPassword(email){

        const config = {
            headers: {
                'Content-Type': 'text/plain'
            },
            withCredentials: true
        };
        return  await axios.post(RootAddress.getURL+"/api/reset-password/", email, config);

    }

    static async checkAuth(){
        const data = {'refreshToken': localStorage.getItem('refreshToken')};
        return await axios.post(RootAddress.getURL+"/api/refreshtoken/", data, {withCredentials: true});

    }

}