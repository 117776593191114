import axios from "axios";
import RootAddress from "./RootAddress";
import AuthService from "./AuthService";

export const API_URL = RootAddress.getURL+'/api/v1/';

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

$api.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`

    //config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbkBpc3R1ZHkuY29tLnJ1IiwiZXhwIjoxNjM4OTg2NjIxLCJpYXQiOjE2Mzg5NTA2MjF9.dy-3mFiVx5Gml0xLMLBRBU5X53YhLGj4VkclqXwZGwA`
    return config;
})

$api.interceptors.response.use((config)=>{
return config;
}, async (error) => {

    const originalRequest = error.config;

    if(error.response.status === 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry = true;
        AuthService.checkAuth().then((r)=>{

                if(r.status === 200){
                    localStorage.setItem('token', r.data.jwt)

                    //localStorage.setItem('token', r.data)
                    return $api.request(originalRequest)
                }
                else {
                    console.log("Please login Htppd")

                }

        })
    }

    throw error;
})

export default $api;