import "./onedot.css"
const OneDot = ({entity}) => {



    return(

            <img className="progress_line_img" src={'/img/'+entity.value} />




    )
}

export default OneDot;