import React, {useState} from "react";
import PostService from "../../../API/PostService";
import AuthService from "../../../API/AuthService";
import {Link} from "react-router-dom";
import Info from "./Info";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
import ReactFlagsSelect from "react-flags-select";
const ResetPassword = () =>{
    const {language, setLanguage} = useLanguage();
    const [languageSelected, setLanguageSelected] = useState(language);
    const [email, setEmail] = useState("");
    const [infoMessage, setInfoMessage] = useState('')

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorEmailMessage, setErrorEmailMessage]  = useState('');

    const [infoShow, setInfoShow] = useState(false)

    const changeLanguage = (code) => {
        setLanguage(code)
        setLanguageSelected(code)

    }
    const reset = () => {
        setInfoMessage(adminTranslations.resetPasswordText2[language])
        setInfoShow(true)
        AuthService.resetPassword(email).then((r)=>{
            setInfoMessage(adminTranslations.sentEmailInfo[language])
            setInfoShow(true)
        }).catch((reason=>{
            const status = reason.response.status;
            if(status === 409){
                setInfoShow(false)
                setErrorEmail(true)
                setErrorEmailMessage(adminTranslations.wrongEmail[language])

            } else if(status === 500){
                setInfoMessage(adminTranslations.error500[language])
                setInfoShow(true)
            }
        }))
    }

    if(infoShow){
        return <Info linkArray={[]} infoMessage={infoMessage}/>
    }
    else{
        return(
            <div className="loginModal">
                <div className="languageLogin">
                    <ReactFlagsSelect
                        selected={languageSelected}
                        countries={["US", "RU"]}
                        customLabels={{"US": "English","RU": "Русский"}}
                        placeholder="Select Language"
                        onSelect={(code) => changeLanguage(code)}
                    />
                </div>
                <input name="email" type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                <p className='wrongPassword' style={{display: errorEmail ? 'block' : 'none'}}>{errorEmailMessage}</p>
                <button onClick={()=>reset()}>{adminTranslations.resetPasswordText[language]}</button>
                <div className="auth__menu">
                    <hr/>
                    <div className="select__reg_log" ><Link to={'/registration'}>{adminTranslations.registrationText[language]}</Link></div>
                    <div className="select__reg_log" ><Link to={'/'}>{adminTranslations.login[language]}</Link></div>
                </div>
            </div>
        )
    }


}

export default ResetPassword;