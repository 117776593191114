import React from "react";
import {Button} from "react-bootstrap";
import "./information.css"
import {adminTranslations} from "../../admin/component/lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"

const Information = ({start}) => {
    const {language} = useLanguage();
    return(
        <div>
            <div className="underPageBody">

            </div>
            <div className="pagebody">

            <div className="information__div__main">
                <div className="information__div">
                    <p>English placement test</p>
                    <Button variant="primary" onClick={()=>start()} >{adminTranslations.start[language]}</Button>
                </div>





                </div>
            </div>

        </div>

                )
}

export default Information;