import "./rawresult.css"
import {parsingDate, parsingTime} from "../../admin/utils/ToolService"
import {projectTranslations} from "./lang/language";
import {useLanguage} from "../../admin/context/LanguageContext"
const RawResult = ({infoMessage}) => {
    const {language} = useLanguage();
    const answers = infoMessage.result.map((answer, key)=>{
        return <div key={key}>
            <p className="result_answer_description">{answer.description}</p>
            <p style={{display: answer.result ? "none" : "block"}} className="result_answer_answer">{answer.answer} false</p>
            <p className="result_answer_correct">{answer.correct} {answer.result ? "true" : ""}</p>
        </div>
    });

    return <div>
        <h2>{projectTranslations.rawResult[language].first} {parsingDate(infoMessage.date, language) }</h2>
        <h3>{projectTranslations.yourLevel[language]}: <b style={{color: "green"}}>{infoMessage.languageLevel}</b></h3>
        <h3>{projectTranslations.rawResult[language].second} <b>{infoMessage.count}</b> {projectTranslations.rawResult[language].third} {infoMessage.total}</h3>
        <h3>{projectTranslations.rawResult[language].fourth} <b>{parsingTime(infoMessage.duration, language)}</b></h3>
        <br/>
        {answers}
    </div>
}

export default RawResult;