import React, {useState} from "react";
import {useParams} from "react-router";
import AuthService from "../../../API/AuthService";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"
import ReactFlagsSelect from "react-flags-select";

const NewPassword = () => {
    const {language, setLanguage} = useLanguage();
    const [languageSelected, setLanguageSelected] = useState(language);
    const [repeatPassword, setRepeatPassword] = useState("");

    const [password, setPassword] = useState("");

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
    const [errorRepeatPasswordMessage, setErrorRepeatPasswordMessage]  = useState('');
    const [errorPasswordMessage, setErrorPasswordMessage]  = useState('');

   const  token  = useParams();
    const changeLanguage = (code) => {
        setLanguage(code)
        setLanguageSelected(code)

    }
    const checkEmpty = (e) => {

        switch (e.target.name){
            case 'password':

                if(e.target.value === ""){
                    setErrorPassword(true)
                    setErrorPasswordMessage(adminTranslations.errorPassword1[language])

                }
                else{

                    if(e.target.value.length <= 3 ){
                        setErrorPassword(true)
                        setErrorPasswordMessage(adminTranslations.errorPassword2[language])
                    }
                    else{
                        setErrorPassword(false)
                    }


                }
                break;
            case 'repeatPassword':
                if(e.target.value === ""){
                    setErrorRepeatPassword(true)
                    setErrorRepeatPasswordMessage(adminTranslations.errorPassword1[language])
                }
                else{
                    if(password === '' && (e.target.value <= 3  )){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword2[language])
                    }
                    else if(password !== '' && e.target.value !== password){
                        setErrorRepeatPassword(true)
                        setErrorRepeatPasswordMessage(adminTranslations.errorPassword3[language])
                    }
                    else{
                        setErrorRepeatPassword(false)
                        setErrorRepeatPasswordMessage("")
                    }


                }
                break;
        }
    }

    const newPassword = () => {
        const data = {email: token, password: password};
        AuthService.newPassword(data).then((r)=>{
            window.location.href = '/';
        }).catch(() => {
            setErrorRepeatPassword(true)
            setErrorRepeatPasswordMessage(adminTranslations.errorPassword0[language])
        })
    }

    return(
        <div  className="loginModal">
            <div className="languageLogin">
                <ReactFlagsSelect
                    selected={languageSelected}
                    countries={["US", "RU"]}
                    customLabels={{"US": "English","RU": "Русский"}}
                    placeholder="Select Language"
                    onSelect={(code) => changeLanguage(code)}
                />
            </div>
            <input  name="password" type="password" onBlur={(e)=>{checkEmpty(e)}} className={errorPassword ? 'red__border' : ''}  placeholder={adminTranslations.passwordText[language]} onChange={(e)=>{setPassword(e.target.value)}} value={password} />
            <p className='wrongPassword' style={{display: errorPassword ? 'block' : 'none'}}>{errorPasswordMessage}</p>

            <input name="repeatPassword" onBlur={(e)=>{checkEmpty(e)}} type="password" className={errorPassword ? 'red__border' : ''} placeholder={adminTranslations.repeatPasswordText[language]} onChange={(e)=>{setRepeatPassword(e.target.value)}} value={repeatPassword} />
            <p className='wrongPassword' style={{display: errorRepeatPassword ? 'block' : 'none'}}>{errorRepeatPasswordMessage}</p>
            <button onClick={()=>newPassword()}>{adminTranslations.savePasswordText[language]}</button>
        </div>
    )
}

export default NewPassword;