import React, {useEffect, useState} from 'react';
import "./main.css";
import {Route, useHistory} from "react-router-dom";
import Dashboard from "../../../pages/list/Dashboard";
import Catalog from "../../../pages/list/Catalog";
import Workplace from "../../../pages/list/Workplace";
import ResultList from "../../../pages/list/ResultList";
import ResultItem from "../../../pages/get/ResultItem";
import Profile from "../profile/Profile";
import {projectTranslations} from "../../../project/component/lang/language";
import {adminTranslations} from "../lang/language";
import {useLanguage} from "../../context/LanguageContext"

export default function Main({isMobile, user, auth}){
    const {language} = useLanguage();
    const history = useHistory();
    const[test, setTest] = useState(null);

    useEffect(()=>{
        if(!auth){
            history.push(`/login`);
        }
    }, [auth])

    const goTest = (test) =>{

        setTest(test);
        history.push(`/workplace`);

    }



    return (
        <div className="main">

            {/*
                <Route path="/dashboard">
                    <Dashboard isMobile={isMobile} title="Dashboard"/>
                </Route>
                */
            }
            <Route path="/catalog" >
                <Catalog user={user} filtered={false}  goTest={goTest} isMobile={isMobile} title={projectTranslations.testCatalog[language]}/>
            </Route>
            <Route exact path="/" >
                <Catalog user={user} filtered={true}  goTest={goTest} isMobile={isMobile} title={projectTranslations.myTests[language]}/>
            </Route>
            <Route exact path="/profile" >
                <Profile user={user} isMobile={isMobile} title={adminTranslations.profile[language]}/>
            </Route>
            <Route path="/workplace">
                <Workplace user={user} test={test} isMobile={isMobile} />
            </Route>
            <Route path='/results/get/:id'  >
                <ResultItem isMobile={isMobile} />
            </Route>
            <Route exact path="/results">
                <ResultList isMobile={isMobile} />
            </Route>



        </div>
    )

}