import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";

const LineChart = ({labelRaw, titleRaw, labelsRaw, dataSetRaw}) => {

    const[labels, setLabels] = useState(labelsRaw);
    const[title, setTitle] = useState(titleRaw)
    const[label, setLabel] = useState(labelRaw);
    const[dataSet, setDataSet] = useState(dataSetRaw);

useEffect(()=>{
    setLabels(labelsRaw)
}, [labelsRaw])

    useEffect(()=>{
        setDataSet(dataSetRaw)
    }, [dataSetRaw])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,

        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: label,
                data: dataSet,
                borderColor: 'rgb(55, 83, 120)',
                backgroundColor: 'rgba(55, 83, 120, 0.5)',
            }

        ],
    };

    return(<div>
        <Line options={options} data={data} />
    </div>)

}

export default LineChart;